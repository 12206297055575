const slug = window.location.pathname;
const channel = document.getElementsByTagName("body")[0].classList[0];

/* Channel-specific functions */
const hideProductSearch = [
  "/personality-profiling",
  "/work-well-suffolk",
  "/refugee-support",
  "/start-up-training",
]; //these pages should not show product search
if (
  (channel === "training" && !hideProductSearch.includes(slug)) ||
  (channel === "events" && !hideProductSearch.includes(slug))
) {
  const productSearch = require("./modules/product-search.js");
}

/* Google My Business reviews */
const googleReviews = require("./modules/googleReviews");

/* Newsletter subscription */
const subscribe = require("./modules/subscribe");
subscribe.init();

/* Dismiss alerts after 3 seconds */
if ($("#newsletter-alert").length) {
  setTimeout(function () {
    $("#newsletter-alert").alert("close");
  }, 3000);
}

/* Count products in basket */
const url = "https://store.menta.org.uk/cart.json?callback=?";
let productCount;
$.ajax({
  dataType: "jsonp",
  url: url,
  success: (cart) => {
    productCount = cart.item_count;
    if (productCount > 0) {
      $("#basket-count").text(productCount);
      $("#basket-count").show();
    }
  },
  error: (err) => {
    console.error(err);
  },
});

/* Add responsive class to tables */
const tables = document.querySelectorAll("table");
for (let table of tables) {
  table.classList.add("table-responsive");
}

function scrollToEnrol() {
  const enrol = document.getElementById("cta");
  if (enrol) {
    enrol.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
}

window.scrollToEnrol = scrollToEnrol;
