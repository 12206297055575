module.exports = {
  init: function toggle(source) {
    const emailInput = document.getElementById("email-subscribe");
    const email = document.getElementById("Email");
    const modalBtn = document.getElementById("openModal");
    const modal = $("#subscribe-form")[0];
    const checkAll = document.getElementById("checkAll");
    var checkboxes = modal.querySelectorAll('input[type="checkbox"]');

    function toggle(source) {
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i] != source) {
          var checkbox = checkboxes[i];

          checkbox.checked = source.checked;
          checkbox.value = checkbox.checked.toString().toUpperCase();
        }
      }
    }

    modalBtn.addEventListener("click", () => {
      let val = emailInput.value;
      email.value = val;
      $(modal).modal("show");
    });

    emailInput.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        let val = emailInput.value;
        email.value = val;
        $(modal).modal("show");
      }
    });

    checkAll.addEventListener("change", (e) => {
      toggle(e.target);
    });
  },
};
