//Shopify JS Buy SDK
import Client from "shopify-buy";
// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  domain: "menta-commerce.myshopify.com",
  storefrontAccessToken: "3d6b4e97e0e2731036007cd159ddb0ce",
});

let checkoutId;

// Create an empty checkout
client.checkout.create().then((checkout) => {
  checkoutId = checkout.id;

  // Do something with the checkout
  productSearchInit();
});

function productSearchInit() {
  let sdkProductData;
  // Fetch all products in your shop
  client.product.fetchAll(100).then((data) => {
    // Do something with the products
    sdkProductData = data;
  });

  //Get product data and convert to object
  //const found_products = document.getElementById("found-products");
  const productSearch = document.getElementById("product_search");
  const productsJson = productSearch.dataset.products;
  const productData = JSON.parse(productsJson);

  //Update number of available products
  // found_products.insertAdjacentText("afterbegin", productData.length);

  //Update date options and ticket options
  function updateOptions(els) {
    const products = document.querySelectorAll(".slick-active .product-card");

    products.forEach((product) => {
      const product_id = product.dataset.product_id;
      const variants = (() => {
        let arr;
        productData.forEach((item) => {
          if (item.id == product_id) {
            arr = item.variants;
          }
        });
        return arr;
      })();
      const price = product.querySelector(".product-price");
      const locations = product.querySelector(".product-locations");
      const dates = product.querySelector(".product-dates");
      const tickets = product.querySelector(".product-tickets");
      const times = product.querySelector(".product-times");
      const btn = product.querySelector(".addToCart");

      const selectedLocation = locations.options[locations.selectedIndex].value;
      let selectedDate;

      if (els.includes("dates")) {
        //Empty old dates and update with new dates
        dates.innerHTML = "";
        let newDates = "";
        variants.forEach((variant) => {
          if (variant.option1 === selectedLocation) {
            newDates += `<option>${variant.option2}</option>`;
          }
        });
        dates.innerHTML = newDates;
      }

      if (els.includes("times")) {
        //Empty old times and update with new times
        times.innerHTML = "";
        let newTimes = "";
        selectedDate = dates.options[dates.selectedIndex].value;
        variants.forEach((variant) => {
          if (
            variant.option1 === selectedLocation &&
            variant.option2 === selectedDate
          ) {
            newTimes += `<option data-id="${variant.id}">${variant.option3}</option>`;
          }
        });
        times.innerHTML = newTimes;
      }

      if (els.includes("tickets")) {
        //Empty old tickets and update with new tickets
        selectedDate = dates.options[dates.selectedIndex].value;
        const selectedTime = times.options[times.selectedIndex].value;
        tickets.innerHTML = "";
        let newTickets = "";
        variants.forEach((variant) => {
          if (
            variant.option1 === selectedLocation &&
            variant.option2 === selectedDate &&
            variant.option3 === selectedTime &&
            variant.inventory_quantity > 0
          ) {
            for (let n = 0; n < variant.inventory_quantity; n++) {
              newTickets += `<option>${n + 1}</option>`;
              btn.removeAttribute("disabled");
              btn.classList.remove("disabled");
            }
          } else if (
            variant.option1 === selectedLocation &&
            variant.option2 === selectedDate &&
            variant.option3 === selectedTime &&
            variant.inventory_quantity <= 0
          ) {
            newTickets += `<option>Sold out!</option>`;
            btn.setAttribute("disabled", "disabled");
            btn.classList.add("disabled");
          }
        });
        tickets.innerHTML = newTickets;
      }

      if (els.includes("price")) {
        selectedDate = dates.options[dates.selectedIndex].value;
        const selectedTime = times.options[times.selectedIndex].value;
        //Empty old price and update with new price
        price.innerHTML = "";
        let newPrice = "";
        variants.forEach((variant) => {
          if (
            variant.option1 === selectedLocation &&
            variant.option2 === selectedDate &&
            variant.option3 === selectedTime
          ) {
            newPrice = `&pound;${variant.price}`;
          }
        });
        price.innerHTML = newPrice;
      }
    });
  }

  //run on page load
  updateOptions(["price", "dates", "times", "tickets"]);

  //add event listeners
  const locationSelectors = document.querySelectorAll(".product-locations");
  const dateSelectors = document.querySelectorAll(".product-dates");
  const timeSelectors = document.querySelectorAll(".product-times");
  const slickArrows = document.querySelectorAll(".slick-arrow");
  const fullDetailsLinks = document.querySelectorAll(".product-full-details");
  const btns = document.querySelectorAll(".addToCart");
  // On swipe event
  $(".product_slick").on("swipe", function (event, slick, direction) {
    updateOptions(["price", "dates", "times", "tickets"]);
  });
  // On changing slide
  $(".product_slick").on("afterChange", function (event, slick, direction) {
    updateOptions(["price", "dates", "times", "tickets"]);
  });
  slickArrows.forEach((arrow) => {
    arrow.addEventListener("click", () => {
      updateOptions(["price", "dates", "times", "tickets"]);
    });
  });
  locationSelectors.forEach((locationSelect) => {
    locationSelect.addEventListener("change", () => {
      updateOptions(["price", "dates", "times", "tickets"]);
    });
  });
  dateSelectors.forEach((dateSelect) => {
    dateSelect.addEventListener("change", () => {
      updateOptions(["price", "times", "tickets"]);
    });
  });
  timeSelectors.forEach((timeSelect) => {
    timeSelect.addEventListener("change", () => {
      updateOptions(["price", "tickets"]);
    });
  });
  fullDetailsLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      const div = e.target.parentElement.parentElement.parentElement;
      const productTitle = div.querySelector(".product-search-title").innerText;
      let productInfo = {};
      productData.forEach((product) => {
        if (product.title === productTitle) {
          productInfo["title"] = product.title;
          productInfo["body"] = product.body_html;
          productInfo["images"] = product.images;
          //productInfo["price"] = product.variants[0].price;
        }
      });
      showProductInfo(productInfo);
    });
  });
  btns.forEach((btn) => {
    btn.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      initBtn(e, sdkProductData);
    });
  });
}

function showProductInfo(info) {
  const modal = document.querySelector("#product-information");
  const productHeader = modal.querySelector(".modal-title");
  const productBody = modal.querySelector(".modal-product-info");
  productHeader.innerHTML = info.title;
  productBody.innerHTML = info.body;
  $(modal).modal("show");
}

function initBtn(e, sdkProductData) {
  const product_card = e.target.closest(".product-card");
  const requires_verification = product_card.dataset.check_eligibility;
  const productTitle = product_card.querySelector(
    ".product-search-title"
  ).innerText;
  const locationSelect = product_card.querySelector(".product-locations");
  const dateSelect = product_card.querySelector(".product-dates");
  const timeSelect = product_card.querySelector(".product-times");
  const quantitySelect = product_card.querySelector(".product-tickets");
  const attendeesInput = product_card.querySelector(".attendee-emails");

  const location = locationSelect.options[locationSelect.selectedIndex].value;
  const date = dateSelect.options[dateSelect.selectedIndex].value;
  const time = timeSelect.options[timeSelect.selectedIndex].value;
  let quantity = quantitySelect.options[quantitySelect.selectedIndex].value;
  quantity = Number(quantity);
  const attendeeEmails = JSON.stringify(attendeesInput.value.split("\n"));
  const variantTitle = `${location} / ${date} / ${time}`;
  const variantId = (() => {
    let id;
    sdkProductData.forEach((product) => {
      if (product.title === productTitle) {
        product.variants.forEach((variant) => {
          if (variant.title === variantTitle) {
            id = variant.id;
          }
        });
      }
    });
    return id;
  })();
  let lineItemsToAdd = {
    variantId: variantId,
    quantity: quantity,
    customAttributes: [{ key: "Attendees", value: attendeeEmails }],
  };
  if (requires_verification) {
    client.checkout.fetch(checkoutId).then((checkout) => {
      checkEligibility(e.target, checkout, lineItemsToAdd);
    });
  } else {
    addToBasket(lineItemsToAdd);
    showAddedToBasket(e.target);
  }
}

function addToBasket(lineItemsToAdd) {
  client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
    updateBasket(checkout);
    addCheckoutLink(checkout.webUrl);
  });
}

function showAddedToBasket(btn) {
  btn.innerText = "Added to basket";
  btn.classList.remove("btn-outline-primary");
  btn.classList.add("btn-primary");
  setTimeout(function () {
    btn.innerText = "Add to basket";
    btn.classList.remove("btn-primary");
    btn.classList.add("btn-outline-primary");
  }, 3000);
}

function updateBasket(checkout) {
  //count basket items
  let productCount = 0;
  checkout.lineItems.forEach((lineItem) => {
    productCount += lineItem.quantity;
  });
  if (productCount > 0) {
    $(".basket-text").text("Go to checkout");
    $(".basket-text").css("text-decoration", "underline");
    $("#topbar").css("background-color", "#f5333f");
    $("#basket-count").text(productCount);
    $("#basket-count").show();
  }
}

function addCheckoutLink(url) {
  $("#checkoutLink").attr("href", url);
}

function checkEligibility(btn, checkout, lineItemsToAdd) {
  //show modal
  const ce_modal = $("#check_eligibility");
  const startUpInputs = document
    .getElementById("start-up-questions")
    .querySelectorAll("input");
  const questions = document
    .getElementById("start-up-questions")
    .querySelectorAll(".line-item-property__field");
  const message = document.getElementById("start-up-message");
  const ce_cancel = document.getElementById("ce_cancel");
  const ce_submit = document.getElementById("ce_submit");
  const questionsLength = questions.length;
  let checkedArr;

  function getCheckedInputs() {
    checkedArr = [];
    startUpInputs.forEach((input) => {
      if (input.checked) {
        checkedArr.push(input.value);
      }
    });
  }

  function checkAnswers() {
    if (checkedArr.includes("Under 16")) {
      message.style.display = "block";
      ce_submit.setAttribute("disabled", "disabled");
      return false;
    } else {
      //save the answers
      const form = document.getElementById("start_up_eligibility");
      const formData = new FormData(form);
      let answers = {};
      for (let [key, prop] of formData) {
        answers[key] = prop;
      }
      const jsonFormData = JSON.stringify([answers]);
      const elData = { key: "Eligibility", value: jsonFormData };
      lineItemsToAdd.customAttributes.push(elData);
      message.style.display = "none";
      ce_submit.removeAttribute("disabled");
      return true;
    }
  }

  function removeRequireEligibility() {
    const product_cards = document.querySelectorAll(".product-card");
    for (let card of product_cards) {
      card.removeAttribute("data-check_eligibility");
      const submit = card.querySelectorAll("button.addToCart");
      submit[0].innerHTML = "Add to basket";
    }
  }

  //add event listeners
  startUpInputs.forEach((input) => {
    input.addEventListener("change", () => {
      checkedArr = [];
      getCheckedInputs();
      checkAnswers();
    });
  });
  ce_submit.addEventListener("click", () => {
    if (checkedArr.length === questionsLength) {
      const validity = checkAnswers();
      //enable add to cart buttons
      if (validity === true) {
        removeRequireEligibility();
        addToBasket(lineItemsToAdd);
        showAddedToBasket(btn);
        updateBasket(checkout);
        addCheckoutLink(checkout.webUrl);
        //close modal
        $("#check_eligibility").modal("hide");
      }
    }
  });

  //open modal
  $(ce_modal).modal("show");
}
